import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.all_loaded
    ? _c(
        VContainer,
        { attrs: { id: "users-table", fluid: "", tag: "section" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: {
                "lazy-validation": _vm.lazy,
                "data-test": "Admin:PartnerTable:FormSearch",
              },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.get_users_by_page()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _vm.filter
                ? _c(
                    VRow,
                    { staticClass: "mt-6", attrs: { justify: "center" } },
                    [
                      _c(VTextField, {
                        staticClass: "mx-3",
                        style: _vm.isMobile()
                          ? "max-width: 25%"
                          : "max-width: 35%",
                        attrs: {
                          dense: "",
                          outlined: "",
                          label: _vm.$t("search"),
                          "data-test": "Admin:PartnerTable:TxtSearchBar",
                        },
                        model: {
                          value: _vm.name_filter,
                          callback: function ($$v) {
                            _vm.name_filter = $$v
                          },
                          expression: "name_filter",
                        },
                      }),
                      _c(VSelect, {
                        staticClass: "mx-3",
                        staticStyle: { "max-width": "30%" },
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Ordenar Por",
                          items: _vm.filter_order_options,
                          "item-text": "text",
                          "item-value": "index",
                        },
                        model: {
                          value: _vm.filter_order_selected,
                          callback: function ($$v) {
                            _vm.filter_order_selected = $$v
                          },
                          expression: "filter_order_selected",
                        },
                      }),
                      _c(
                        VBtn,
                        {
                          staticClass: "mx-3 secondary",
                          attrs: { type: "submit" },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-center" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("name")) + " "),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color: _vm.orderBy == -1 ? "primary" : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(-1)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == 0
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          ) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("email"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$tc("company_partner", 1))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("options"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.users, function (user, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "td",
                    {
                      staticClass: "text-center",
                      attrs: {
                        "data-test":
                          "Admin:PartnerTable:TdName:" + _vm.show_name(user),
                      },
                    },
                    [
                      _c("div", { staticClass: "div-user-list-name" }, [
                        _vm._v(_vm._s(_vm.show_name(user))),
                      ]),
                      user.Deleted
                        ? _c("div", { staticClass: "div-deleted" }, [
                            _vm._v(" " + _vm._s(_vm.$t("deleted_user")) + " "),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(user.Email)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(user.PartnerB2bName)),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 secondary",
                          attrs: { "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.edit_user(user.Id)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: {
                            color: "red",
                            "min-width": "0",
                            small: "",
                            disabled: user.Deleted,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.open_delete_dialog(user)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(VPagination, {
            attrs: { color: "secondary", length: _vm.total_pages },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: {
                      obj: _vm.delete_obj,
                      name: _vm.delete_obj.Name + " " + _vm.delete_obj.LastName,
                    },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.delete_user,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }